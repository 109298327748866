<template>
    <div class="test-timer">
        <div class="test-end__title">
            <div class="test-end__pic">
                <img class="test-end__img" src="~assets/img/favicons/question-icon.svg" alt="" width="55" height="55">
            </div>
            <span>Ожидайте результаты тестирования!</span>
        </div>
        <div class="test-end__count-block">
            <div class="test-end__section">
                <span class="test-end__count">{{ test.answer_correct }}</span>
                <span class="test-end__lore">
                    {{ functions.num2str(test.answer_correct, phrases.correct) }}
                    {{ functions.num2str(test.answer_correct, phrases.answer) }}
                    из
                    {{test.questions_count}}
                </span>
            </div>
            <div class="test-end__section" v-if="test.answer_wait > 0">
                <span class="test-end__count">{{ test.answer_wait }}</span>
                <span class="test-end__lore">
                    {{ functions.num2str(test.answer_wait, phrases.answer) }}
                    {{ functions.num2str(test.answer_wait, phrases.waiting) }}
                    проверки
                </span>
            </div>
        </div>
        <router-link v-if="false" tag="a" to="/" class="test__link-to-main mt-50">
            Вернуться на главную
        </router-link>
    </div>
</template>

<script>
    import session from '@/api/session';
    import functions from "assets/js/functions";
    export default {
        name: "Wait",
        data() {
            return {
                functions,
                phrases: {
                    correct: ['правильный', 'правильных', 'правильных'],
                    answer: ['ответ', 'ответа', 'ответов'],
                    waiting: ['ожидает', 'ожидают', 'ожидают']
                },
                test: {}
            }
        },
        methods: {
            async loadData() {
                const request = await session.get('/api/v1/testing/end/');
                this.test = request.data;
            }
        },
        async created() {
            await this.loadData();
        }
    }
</script>

<style lang="scss" scoped>
@import "stages";
</style>
